import {
  Button,
  Flex,
  LightMode,
  Stack,
  Textarea,
} from '@chakra-ui/react'
import * as React from 'react'
import { InputField } from './InputField'

const ContactForm = () => {
  return (
    <form
      // onSubmit={(e) => {
      //   // add submit logic here
      //   e.preventDefault()
      // }}
      method="post" action="https://formspree.io/f/mpzkaqol"
    >
      <Stack spacing="8">
        <InputField label="Name" type="text" name="name" />
        <InputField label="Email" type="email" name="email" />
        <InputField label="Phone" type="text" name="phone" />
        <Textarea placeholder="Your Message" name="message" />
      </Stack>
      <Flex
        spacing="4"
        direction={{ base: 'column-reverse', md: 'row' }}
        mt="6"
        align="center"
        justify="space-between"
      >
        <LightMode>
          <Button
            mb={{ base: '4', md: '0' }}
            w={{ base: 'full', md: 'auto' }}
            type="submit"
            colorScheme="bullseyeOrange"
            size="lg"
            fontSize="md"
            fontWeight="bold"
          >
            Submit
          </Button>
        </LightMode>
      </Flex>
    </form>
  )
}

export default ContactForm