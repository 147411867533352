import React from "react"
import { Box, Heading, Text, Flex, useColorModeValue } from "@chakra-ui/react"
import { FaPhone } from "react-icons/fa"
import Layout from "../../components/Layout"
import Meta from "../../components/Meta"
import ContactForm from "../../components/forms/ContactForm"

const Contact = () => {
  return (
    <Layout>
      <Meta
        title="Contact Bullseye Lawn Care"
        description="If you are in need of lawn care experts in the Sioux Falls area you have come to the right place. Contact us to learn how we can help you with your lawn."
      />
      <Flex minH="100vh" direction={{ base: "column", md: "row" }}>
        <Box
          display={{ base: "none", md: "block" }}
          maxW={{ base: "20rem", lg: "40rem" }}
          flex="1"
          bg="bullseyeGreen.600"
          color="white"
          px="10"
          py="8"
        >
          <Flex
            direction="column"
            align="center"
            justify="center"
            h="full"
            textAlign="center"
            mt="-10"
          >
            <Box>
              <Text
                maxW="md"
                mx="auto"
                fontWeight="extrabold"
                fontSize={{ base: "4xl", lg: "5xl" }}
                letterSpacing="tight"
                lineHeight="normal"
              >
                Want to contact us?
              </Text>
              <Text mt="5" maxW="sm" mx="auto" fontSize={{ base: "xl" }}>
                Give us a call at 605-204-5458 or fill out the form on the
                right. We will get back to you soon!
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box
          flex="1"
          px={{ base: "6", md: "10", lg: "16", xl: "28" }}
          py={{ base: "10", md: "48" }}
          bg={{ md: useColorModeValue("gray.50", "gray.800") }}
        >
          <Box maxW="xl">
            <Box>
              <Heading
                color={useColorModeValue(
                  "bullseyeGreen.600",
                  "bullseyeGreen.400"
                )}
                as="h1"
                size="2xl"
                fontWeight="extrabold"
                letterSpacing="tight"
              >
                Let's stay in touch
              </Heading>
              <Flex display={{ base: "block", md: "none" }} pt={4}>
                <Text
                  as="h3"
                  color={useColorModeValue("orange.500", "orange.400")}
                  fontSize="xl"
                  fontWeight="bold"
                >
                  <Flex>
                    <Text as={FaPhone} my={1} mx={2} />
                    <Text>(605) 204-5458</Text>
                  </Flex>
                </Text>
              </Flex>
              <Text
                mt="3"
                fontSize={{ base: "xl", md: "3xl" }}
                fontWeight="bold"
                color={useColorModeValue("gray.500", "inherit")}
              >
                Contact us using the form below
              </Text>
            </Box>

            <Box
              minW={{ md: "420px" }}
              mt="10"
              rounded="xl"
              bg={{ md: useColorModeValue("white", "gray.700") }}
              shadow={{ md: "lg" }}
              px={{ md: "10" }}
              pt={{ base: "8", md: "12" }}
              pb="8"
            >
              <ContactForm />
            </Box>
          </Box>
        </Box>
      </Flex>
    </Layout>
  )
}

export default Contact
